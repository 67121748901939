<template>
  <div class="tdetail">
    <div class="left-main">
      <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>
      <div class="top">
        <div class="title">{{ paper.title }}</div>
      </div>
      <div class="info g-flex g-flex-jcsb">
        <div class="left-text">
          <span>被评价人：{{ list.ratabledName }}</span>
          <span>
            评价状态：
            <small>{{ list.status ? '已评价' : '未评价' }}</small>
          </span>
          <span>时间：{{ $moment(time).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </div>
        <span>
          分数：
          <strong v-if="isDtSchool()">{{ totalSumRatio >= 0 ? totalSumRatio:0  || list.answerTotalScore }}</strong>
          <strong v-else>{{ totalSum(list.targetVoList) || list.answerTotalScore }}</strong>
        </span>
      </div>
      <template v-if="list.targetVoList && list.targetVoList.length">
        <div class="paper-head">
          <div class="item" v-for="(item, index) in paperHead" :key="index">
            <span>{{ item.name }}：</span>
            <span v-if="item.status == 1">{{ item.value }}</span>
            <el-input v-model="item.value" placeholder="请输入" v-if="item.status == 0" :disabled="list.status"></el-input>
          </div>
        </div>
        <!-- 一级 固定 范围 等级-->
        <div class="target1-content" v-if="paper.targetType == 1">
          <div v-for="(item, index) in list.targetVoList" :key="item.id" class="target1-item" :class="isChecked && paper.scoreType != 1 && !item.answer && item.answer != 0 ? 'no-answer' : ''">
            <div v-if="paper.scoreType == 1" class="checkbox-group">
              <el-checkbox-group v-model="item.answer" @change="addStorage(item)" :id="item.id">
                <el-checkbox :label="item.score" :disabled="list.status">{{ index + 1 }}.{{ item.name }}({{ item.score }}分)</el-checkbox>
              </el-checkbox-group>
            </div>
            <template v-else>
              <div class="target1-item-tit" :id="item.id">
                <i style="color: #1c9dff">{{ index + 1 }}.</i>
                {{ item.name }}
                <span v-if="paper.scoreType == 2">
                  ({{ item.minScore }}-{{ item.maxScore }}分)
                  <el-tooltip
                    effect="dark"
                    content="不可操作，该指标已关联教师行为指标！"
                    placement="right"
                    v-if="item.status == '1'"
                  >
                    <i class="el-icon-question" style="color:#ff4d4f"></i>
                  </el-tooltip>
                </span>
              </div>
              <div v-if="paper.scoreType == 2">
                <el-input
                  type="number"
                  :disabled="list.status || item.status == '1'"
                  v-model="item.answer"
                  @blur="numberChange($event, item)"
                  style="width: 90%"
                  @change="addStorage(item)"
                  class="input-group"
                />
              </div>
              <div v-if="paper.scoreType == 3">
                <el-radio-group v-model="item.answer" @change="addStorage(item)">
                  <el-radio v-for="i in item.levelDetailVos" :key="i.id" :label="i.score" :disabled="list.status">{{ i.name }}({{ i.score }}分)</el-radio>
                </el-radio-group>
              </div>
            </template>
          </div>
        </div>
        <!-- 其余级 固定 范围 等级 -->
        <div class="target-other-content" v-else>
          <div v-for="(item, index) in list.targetVoList" :key="item.id" :id="item.id">
            <div class="first-tit">
              <span class="num">{{ numberToChinese(index + 1) }}</span>
              <span class="title">{{ item.name }}</span>
              <span class="score">
                当前分值：
                <strong style="color: #fff075">{{ totalAnswer(item) }}</strong>
              </span>
              <span class="score" v-if="isDtSchool()">
                占比分值：
                <strong style="color: #fff075">{{ totalAnswerRatio(item)  }}</strong>
              </span>
            </div>
            <!-- 二级 -->
            <div class="items-main" v-if="paper.targetType == 2">
              <div class="item" :class="paper.scoreType == 3 ? 'bottom_line' : ''" v-for="opt in item.children" :key="opt.id" :id="opt.id">
                <template v-if="paper.scoreType == 1">
                  <el-checkbox-group v-model="opt.answer" @change="addStorage(opt)">
                    <el-checkbox :label="opt.score" :disabled="list.status">{{ opt.num }}.{{ opt.name }}({{ opt.score }}分)</el-checkbox>
                  </el-checkbox-group>
                </template>
                <div v-if="paper.scoreType == 2" class="line" :class="isChecked && !opt.answer && opt.answer != 0 ? 'no-answer' : ''">
                  <h4 class="timu">
                    {{ opt.num }}.{{ opt.name }} ({{ opt.minScore }}-{{ opt.maxScore }}分)
                    <el-tooltip
                      effect="dark"
                      content="不可操作，该指标已关联教师行为指标！"
                      placement="right"
                      v-if="opt.status == '1'"
                    >
                      <i class="el-icon-question" style="color:#ff4d4f"></i>
                    </el-tooltip>
                  </h4>
                  <el-input
                    type="number"
                    :disabled="list.status || opt.status == '1'"
                    v-model="opt.answer"
                    @blur="numberChange($event, opt)"
                    style="width: 90%"
                    @change="addStorage(opt)"
                  />
                </div>
                <div v-if="paper.scoreType == 3" class="line" :class="isChecked && !opt.answer ? 'no-answer' : ''">
                  <h4 class="timu">{{ opt.num }}.{{ opt.name }}</h4>
                  <el-radio-group v-model="opt.answer" @change="addStorage(opt)">
                    <el-radio v-for="i in opt.levelDetailVos" :key="i.id" :label="i.score" :disabled="list.status">{{ i.name }}({{ i.score }}分)</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <!-- 三级 -->
            <div v-else v-for="(opt, i) in item.children" :key="opt.id">
              <div class="second-tit" v-if="paper.targetType == 3">
                <i style="color: #1c9dff">{{ i + 1 }}.</i>
                {{ opt.name }}
                <!-- 勿删 计算中间级（2）分数 -->
                <div style="display: none">{{ totalAnswer(opt) }}</div>
              </div>
              <div class="items-main">
                <div v-for="item1 in opt.children" :key="item1.id" class="item line" :class="[[paper.scoreType == 3 ? 'bottom_line' : ''], [isChecked && !item1.answer && item1.answer != 0 ? 'no-answer' : '']]" :id="item1.id">
                  <template v-if="paper.scoreType == 1">
                    <el-checkbox-group v-model="item1.answer" @change="addStorage(item1)">
                      <el-checkbox :label="item1.score" :disabled="list.status" data-item="item1">{{ item1.num }}.{{ item1.name }}({{ item1.score }}分)</el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <template v-if="paper.scoreType == 2">
                    <h4 class="timu">
                      {{ item1.num }}.{{ item1.name }} ({{ item1.minScore }}-{{ item1.maxScore }}分)
                      <el-tooltip
                        effect="dark"
                        content="不可操作，该指标已关联教师行为指标！"
                        placement="right"
                        v-if="item1.status == '1'"
                      >
                        <i class="el-icon-question" style="color:#ff4d4f"></i>
                      </el-tooltip>
                    </h4>
                    <el-input
                      type="number"
                      :disabled="list.status || item1.status == '1'"
                      v-model="item1.answer"
                      @blur="numberChange($event, item1)"
                      style="width: 90%"
                      @change="addStorage(item1)"
                    />
                  </template>
                  <template v-if="paper.scoreType == 3">
                    <h4 class="timu">{{ item1.num }}.{{ item1.name }}</h4>
                    <el-radio-group v-model="item1.answer" @change="addStorage(item1)">
                      <el-radio v-for="i in item1.levelDetailVos" :key="i.id" :label="i.score" :disabled="list.status">{{ i.name }}({{ i.score }}分)</el-radio>
                    </el-radio-group>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="paper-footer">
          <div class="item" v-for="(item, index) in paperFooter" :key="index">
            <div class="tit">{{ item.name }}</div>
            <el-input :autosize="{ minRows: 4, maxRows: 6 }" type="textarea" v-model="item.value" placeholder="请输入" :disabled="list.status"></el-input>
          </div>
        </div>
        <div class="btns">
          <el-button class="button" type="primary" plain @click="goBack">返回</el-button>
          <el-button v-if="!list.status" class="button" type="primary" plain @click="reset">重置</el-button>
          <el-button class="button" type="primary" v-if="!list.status" @click="submit">提交</el-button>
          <el-button class="button next-btn" plain @click="toNext" v-if="nextRatableds.length && !list.status">下一位：{{ nextRatableds[0].ratabledName }}</el-button>
        </div>
      </template>
      <div v-else class="b-blank">
        <div class="img"></div>
        <p>暂无内容</p>
      </div>
    </div>

    <!-- 右侧答题卡 -->
    <div class="right-content">
      <div class="main">
        <div class="title">
          <span style="margin-left: 10px">答题卡</span>
        </div>
        <template v-if="paper.targetType == 1">
          <div class="zhibiao">{{ paper.title }}</div>
          <div class="box">
            <a class="item" :href="'#' + item.id" :class="item.answer | filterLight" v-for="(item, index) in list.targetVoList" :key="index">{{ index + 1 }}</a>
          </div>
        </template>
        <template v-else>
          <div v-for="item in list.targetVoList" :key="item.id">
            <div class="zhibiao">{{ item.name }}</div>
            <div class="box">
              <template v-for="it in item.children">
                <a class="item" :class="it.answer | filterLight" :key="it.id" :href="'#' + it.id" v-if="it.hasChild == 'false'">{{ it.num }}</a>
                <template v-else v-for="li in it.children">
                  <a class="item" :class="li.answer | filterLight" :key="li.id" :href="'#' + li.id">{{ li.num }}</a>
                </template>
              </template>
            </div>
          </div>
        </template>
      </div>

      <div class="foot">
        <div class="con">
          <div class="finish">已填</div>
          <div>未填</div>
        </div>
      </div>
    </div>

    <el-backtop :bottom="90"></el-backtop>
  </div>
</template>

<script>
import { cloneObject } from '@/utils';
export default {
  data() {
    return {
      list: {},
      dataSource: {},
      paper: {},
      time: '',
      unRevieweds: [],
      revieweds: [],
      paperId: '',
      weightId: '',
      ratabledId: '',
      ratabledName: '',
      nextName: '',
      nextIndex: '',
      answerList: [],
      actives: [],
      scrollTop: 0,
      isChecked: false,
      checkArr: [],
      storageArr: [],
      storageObj: {},
      nextRatableds: [],
      paperFooter: [],
      paperHead: [],
    };
  },
  filters: {
    filterLight(data) {
      let str = '';
      if (Array.isArray(data)) {
        str = data.length ? data.join() : undefined;
      }
      if (data == null) {
        str = undefined;
      }
      if (str || str == 0) {
        return 'active';
      }
    },
  },
  computed: {
    totalAnswer() {
      return function (data) {
        let sum = 0;
        function recursion(list) {
          if (list.hasChild == 'true') {
            list.children.forEach(item => {
              recursion(item);
            });
          } else {
            sum = Number(list.answer) + Number(sum);
          }
        }
        recursion(data);
        data.answer = sum;
        return sum;
      };
    },
    totalSum() {
      return function (list) {
        if (list && list.length) {
          let total = list.reduce((prev, cur) => {
            return prev + Number(cur.answer);
          }, 0);
          this.list.answerTotalScore = total;
          return total;
        }
      };
    },
    // 计算大同学校百分比分值
    totalAnswerRatio(){
      return function (data) {
        let sum = 0;
        function recursion(list) {
          if (list.hasChild == 'true') {
            list.children.forEach(item => {
              recursion(item);
            });
          } else {
            sum = Number(list.answer) + Number(sum);
          }
        }
        recursion(data);
        data.answer = sum;
        let metricsPercentage = data.metricsPercentage?Number(data.metricsPercentage) : 100
        let result = sum * (metricsPercentage / 100)
        return Number.isInteger(result) ? result: result.toFixed(1);
      };
    },
    // 计算大同学校百分比分值 总分
    totalSumRatio() {
      if (this.list && this.list.targetVoList && this.list.targetVoList.length) {
        return this.list.targetVoList.reduce((prev, cur) => {
          let metricsPercentage = cur.metricsPercentage ? Number(cur.metricsPercentage) : 100;
          let num = prev + Number(cur.answer) * (metricsPercentage / 100);
          let total = Number.isInteger(num) ? num : parseFloat(num.toFixed(1));
          this.list.answerTotalScore = total
          return total;
        }, 0);
      }
      return 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop, true);
    this.ratabledId = this.$route.query.ratabledId;
    this.paperId = this.$route.query.paperId;
    this.weightId = this.$route.query.weightId;
    this.nextRatableds = JSON.parse(this.$route.query.nextArr);
    this.loadData();
    this.isDtSchool()
  },
  methods: {
    // 是否是大同学校
    isDtSchool(){
      let schoolName = JSON.parse(localStorage.getItem('ss-userInfo')).schoolName
      return schoolName == "大同卫生学校"
    },
    // 数字转大写
    numberToChinese(num) {
      var num = Number(num);
      var upperCaseNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
      var length = String(num).length;
      if (length == 1) {
        return upperCaseNumber[num];
      } else if (length == 2) {
        if (num == 10) {
          return upperCaseNumber[num];
        } else if (num > 10 && num < 20) {
          let index = String(num).charAt(1);
          return '十' + upperCaseNumber[Number(index)];
        } else {
          let index0 = String(num).charAt(0);
          let index = String(num).charAt(1);
          return upperCaseNumber[Number(index0)] + '十' + upperCaseNumber[Number(index)].replace('零', '');
        }
      }
    },
    // 输入框限制大小
    numberChange(e, item) {
      let val = Number(e.target.value);
      let maxNum = Number(item.maxScore);
      let minNum = Number(item.minScore);
      if (val < minNum) {
        this.$message('您输入的内容小于最小值，将为您重置为最小值');
        item.answer = minNum;
      } else if (val > maxNum) {
        this.$message('您输入的内容大于最大值，将为您重置为最大值');
        item.answer = maxNum;
      } else {
        item.answer = parseInt(val);
      }
    },
    // 获取接口数据 async
    loadData() {
      // 评价人 表 方式
      this.$get('/evas/paper/student/getStudentEvasVo', {
        ratabledId: this.ratabledId,
        paperId: this.paperId,
        weightId: this.weightId,
      }).then(res => {
        this.time = res.result.time;
        this.ratabledName = res.result.ratabledName;
        this.paper = res.result.paper;
        // 复选 string ---> arr
        if (this.paper.scoreType == 1) {
          this.formatArr(res.result.targetVoList);
        }

        this.list = res.result;
        this.paperHead = JSON.parse(res.result.paper.evasInfo);
        this.paperFooter = JSON.parse(res.result.paper.evasFooterInfo);

        // 显示缓存
        if (localStorage.getItem('storageObj') && !res.result.status) {
          let obj = JSON.parse(localStorage.getItem('storageObj'));
          if (Object.keys(obj).join() == this.paperId + '-' + this.ratabledId) {
            this.storageArr = Object.values(obj)[0];
            this.StorageRecursion(this.list.targetVoList);
            this.paperHead = this.storageArr[this.storageArr.length - 2].paperHead;
            this.paperFooter = this.storageArr[this.storageArr.length - 1].paperFooter;
          }
        }
      });
    },
    // 修改数据格式 string - > arr
    formatArr(data) {
      data.forEach(item => {
        if (item.hasChild == 'true') {
          this.formatArr(item.children);
        } else {
          item.answer = item.answer ? item.answer.split(',') : [];
        }
      });
    },
    // 递归
    StorageRecursion(list) {
      list.forEach((item, index) => {
        if (item.hasChild == 'true') {
          this.StorageRecursion(item.children);
        } else {
          this.storageArr.forEach(li => {
            if (li.key == item.id) {
              item.answer = li.value;
            }
          });
        }
      });
    },
    // 获取已评未评
    // async loadmovies() {
    //   let res = await api.getRataList({
    //     paperId: this.paperId,
    //   });
    //   if (res.success) {
    //     this.unRevieweds = res.result.unRevieweds;
    //     this.revieweds = res.result.revieweds;
    //     this.unRevieweds.forEach((item, index) => {
    //       if (item.ratabledId == this.ratabledId) {
    //         this.nextIndex = index;
    //       }
    //       if (this.nextIndex + 1 != this.unRevieweds.length) {
    //         this.nextName = this.unRevieweds[this.nextIndex + 1].ratabledName;
    //       } else {
    //         this.nextName = this.unRevieweds[0].ratabledName;
    //       }
    //     });
    //   }
    // },
    // 返回
    goBack() {
      console.log("item", this.$route.query.method)
      this.storageArr.push({
        paperHead: this.paperHead,
      });
      this.storageArr.push({
        paperFooter: this.paperFooter,
      });
      this.storageObj = {
        [this.paperId + '-' + this.ratabledId]: this.storageArr,
      };
      localStorage.setItem('storageObj', JSON.stringify(this.storageObj));
      this.$router.push({
        name: 'studentEvaluatePeople',
        query: {
          paperId: this.paperId,
          method: this.$route.query.method,
        },
      });
    },
    // 重置
    reset() {
      this.isChecked = false;
      this.ratabledId = this.list.ratabledId;
      this.loadData();
      this.storageObj = {};
      this.storageArr = [];
      localStorage.removeItem('storageObj');
    },
    // 添加到缓存中
    addStorage(row) {
      let index = '';
      if (this.storageArr.length) {
        index = this.storageArr.findIndex(item => {
          return item.key == row.id;
        });
        if (index >= 0) {
          this.storageArr.splice(index, 1, {
            key: row.id,
            value: row.answer,
          });
        } else {
          this.storageArr.push({
            key: row.id,
            value: row.answer,
          });
        }
      } else {
        this.storageArr.push({
          key: row.id,
          value: row.answer,
        });
      }
      this.storageObj = {
        [this.paperId + '-' + this.ratabledId]: this.storageArr,
      };
    },
    // 点击下一位回到顶部
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    },
    // 校验必填
    recursive(list) {
      list.forEach((item, index) => {
        if (item.hasChild == 'true') {
          this.recursive(item.children);
        } else {
          this.checkArr.push(item.answer);
        }
      });
    },
    // 处理格式:固定值数组转字符串
    changeFormat(list) {
      list.forEach((item, index) => {
        if (item.hasChild == 'true') {
          this.changeFormat(item.children);
        } else {
          item.answer = item.answer.join();
        }
      });
    },
    // 提交接口
    postPaper(type) {
      this.list.weightId = this.weightId;
      this.list.paper.evasInfo = JSON.stringify(this.paperHead);
      this.list.paper.evasFooterInfo = JSON.stringify(this.paperFooter);
      const data = cloneObject(this.list);
      if (this.paper.scoreType == 1) {
        this.changeFormat(data.targetVoList);
      }
      this.$post('/evas/answer/student/saveAnswer', data).then(res => {
        if (res.success) {
          this.list.status = true;
          this.$message({
            type: 'success',
            message: `评价成功!`,
          });
          if (type == 'next') {
            this.ratabledId = this.nextRatableds[0].ratabledId;
            this.nextRatableds.splice(0, 1);
            this.loadData();
          }
          this.backTop();
        } else {
          this.$message({
            type: 'warning',
            message: res.message,
          });
        }
        this.isChecked = false;
      });
    },
    // 提交
    submit(type) {
      this.isChecked = true;
      if (this.paper.scoreType == 2 || this.paper.scoreType == 3) {
        this.checkArr = [];
        this.recursive(this.list.targetVoList);
        let tag = this.checkArr.some(li => {
          return li === null || li === '' || li === undefined;
        });
        if (tag) {
          this.$message({
            type: 'warning',
            message: '题目未做完，请答完后再提交',
          });
        } else {
          this.storageObj = {};
          this.storageArr = [];
          localStorage.removeItem('storageObj');
          this.postPaper(type);
        }
      } else {
        // 无校验 接口 处理格式
        this.postPaper(type);
        this.storageObj = {};
        this.storageArr = [];
        localStorage.removeItem('storageObj');
      }
    },
    // 下一位
    toNext() {
      this.submit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.tdetail {
  display: flex;
  align-items: flex-start;
  .left-main {
    flex: 1;
    min-height: calc(100vh - 182px);
    background-color: #fff;
    padding: 40px;
    box-sizing: border-box;
    margin-right: 5px;
    .top {
      margin-bottom: 20px;
      color: #343a52;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 2px;
      text-align: center;
      .title {
        display: inline-block;
        padding: 0 90px;
        box-sizing: border-box;
        position: relative;
        &::before,
        &::after {
          content: '';
          display: block;
          width: 48px;
          height: 27px;
        }
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background: url(~@/assets/img/10_EVAL_mineLearnMore/title_left.png) no-repeat center center;
          background-size: 100% 100%;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background: url(~@/assets/img/10_EVAL_mineLearnMore/title_right.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .info {
      padding-bottom: 20px;
      border-bottom: 1px solid #e1e1e1;
      .left-text {
        span {
          margin-right: 34px;
        }
      }
      small {
        font-size: 16px;
        color: #4691f3;
      }
      strong {
        color: #f7bb12;
      }
    }
    .paper-head {
      background: #f2faff;
      padding: 16px 30px 0;
      font-size: 16px;
      color: #000;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .item {
        display: inline-block;
        margin-bottom: 16px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        .el-input {
          flex: 1;
        }
      }
      .item:last-child {
        margin-right: 0;
      }
    }
    .target1-content {
      border: 1px dotted #e1e1e1;
      box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.02);
      border-radius: 10px;
      margin: 23px 0;
      padding: 18px 24px 0 14px;
      box-sizing: border-box;
      .target1-item {
        padding: 5px;
        margin: 5px 0;
      }
      .target1-item-tit {
        background: #f2faff;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
        color: #343a52;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .input-group {
        margin-bottom: 20px;
      }
    }
    .target-other-content {
      .first-tit {
        display: flex;
        background: #1c9dff;
        border-radius: 10px;
        margin: 35px 0 12px;
        align-items: center;
        font-size: 20px;
        color: #fff;
        .num {
          display: inline-block;
          min-width: 36px;
          height: 36px;
          background: #fff;
          box-shadow: 0px 0px 6px 0px rgba(177, 207, 255, 0.61);
          margin-left: -16px;
          line-height: 36px;
          text-align: center;
          border-radius: 50%;
          font-size: 19px;
          color: #1c9dff;
          font-weight: bold;
        }
        .title {
          font-weight: bold;
          padding: 0 29px 0 23px;
          box-sizing: border-box;
          line-height: 48px;
          max-width: 84%;
          letter-spacing: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .score {
          padding-left: 24px;
          font-size: 16px;
          border-left: 1px solid #e1e1e1;
        }
        .score+.score{
          margin-left: 24px;
        }
      }
      .second-tit {
        margin-bottom: 10px;
        background: #f2faff;
        border: 1px dotted #1c9dff;
        box-shadow: 0px 2px 3px 0px rgba(177, 207, 255, 0.39);
        border-radius: 10px;
        padding: 8px 20px 8px 29px;
        box-sizing: border-box;
        color: #343a52;
      }
      .items-main {
        border: 2px dotted #e1e1e1;
        box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 7px 28px 26px;
        box-sizing: border-box;
        .item {
          margin-top: 25px;
          .timu {
            margin-bottom: 10px;
            font-size: 18px;
            color: #000;
          }
          h4.timu {
            font-weight: normal;
          }
        }
      }
    }
    .line {
      padding: 5px;
      box-sizing: border-box;
    }
    .bottom_line {
      border-bottom: 1px solid #e1e1e1;
    }
    .no-answer {
      border: 1px solid red !important;
      border-radius: 6px;
    }
    /deep/.el-radio-group {
      width: 100%;
      .el-radio {
        display: block;
        margin-bottom: 14px;
        margin-right: 0;
        text-overflow: ellipsis;
        white-space: normal;
        line-height: 24px;
        padding: 5px;
        &__label {
          font-size: 16px;
          word-break: break-all;
          color: rgba(0,0,0,.6);
        }
      }
      .el-radio__input.is-disabled.is-checked .el-radio__inner {
        border-color: #3f84ee;
        &::after {
          background-color: #3f84ee;
        }
      }
    }
    /deep/.el-checkbox-group {
      width: 100%;
      .el-checkbox {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: normal;
        padding: 5px;
        &__label {
          font-size: 18px;
          color: rgba(0,0,0,.6);
        }
      }
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
        &::after {
          border-color: #3f84ee;
        }
      }
    }
    /deep/.el-input__inner {
      padding-right: 0;
    }
    .paper-footer {
      margin-top: 40px;
      .item {
        margin-bottom: 20px;
        background: #1c9dff;
        border-radius: 10px;
        .tit {
          font-size: 18px;
          color: #fff;
          font-weight: 600;
          padding: 10px 20px;
        }
      }
    }
  }
}
.right-content {
  width: 180px;
  background-color: #fff;
  border: 1px solid rgba(225, 227, 233, 0.3);
  position: sticky;
  position: -webkit-sticky;
  top: 5%;
  .main {
    height: calc(100vh - 184px);
    overflow-y: auto;
  }
  .title {
    position: relative;
    font-weight: bold;
    color: #383d55;
    margin: 20px;
    &::before {
      content: '';
      width: 4px;
      height: 16px;
      background: #3f84ee;
      position: absolute;
      top: 4px;
      border-radius: 2px;
    }
  }
  .zhibiao {
    height: 22px;
    line-height: 22px;
    background: #f2faff;
    border-radius: 3px;
    margin: 0 19px 10px;
    padding: 0 4px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: #3f84ee;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .box {
    margin-left: 20px;
    .item {
      display: inline-block;
      width: 20px;
      height: 22px;
      background: #f1f3f8;
      border: 1px solid rgba(174, 186, 216, 0.3);
      border-radius: 6px;
      margin-bottom: 10px;
      margin-right: 6px;
      text-align: center;
      line-height: 24px;
      color: #7b7b7b;
      font-size: 14px;
    }
    .active {
      background: #1288fe;
      color: #fff;
    }
  }
  .foot {
    background: #fbfcff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    .con {
      margin: 0 30px;
      div {
        position: relative;
        display: inline-block;
        width: 50%;
        font-size: 12px;
        color: #a7a7a7;
        padding-left: 4px;
        box-sizing: border-box;
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          background: #a7a7a7;
          position: absolute;
          top: 3px;
          left: -10px;
        }
      }
      .finish {
        &::before {
          background: #1a8cfe;
        }
      }
    }
  }
}
.left-main {
  .el-checkbox:hover,
  .el-radio:hover {
    background: #fffaf2;
  }
  .el-checkbox.is-disabled:hover,
  .el-radio.is-disabled:hover {
    background: rgba(0, 0, 0, 0);
  }
}
.checkbox-group {
  padding: 10px 0;
}
.btns {
  text-align: center;
  margin-top: 34px;
  .button {
    width: 114px;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
  }
  .next-btn {
    min-width: 157px;
    width: auto;
    border: 1px solid #ff9736;
    color: #ff9736;
    margin-left: 30px;
  }
}
</style>
